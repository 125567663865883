import { request } from "@/request/http";

/**
 * @name: 学校管理接口
 * @author: LXY
 * @date: 2022-02-28 
 */
export default {
    // 获取虚拟实验分类列表
    getExpLabList: (data) => {
        return request("POST", `/index.php/adminExp/getExpLabList`, data);
    },
    //   通过名称搜索开发者
    getDevelopByName: (data) => {
        return request("POST", `/index.php/adminExp/getDevelopByName`, data);
    },
    //   获取虚拟实验信息通过expid
    getExpInfoById: (data) => {
        return request("POST", `/index.php/adminExp/getExpInfoById`, data);
    },
    //   添加/修改虚拟实验
    updateExp: (data) => {
        return request("POST", `/index.php/adminExp/updateExp`, data);
    },
    // 获取文章列表
    getArticleList: (data) => {
        return request("POST", `/index.php/adminExp/getArticleList`, data);
    },
    // 获取学校列表
    getSchoolList: (data) => {
        return request("POST", `/index.php/adminSchool/getSchoolList`, data);
    },
    createCourseAndProjectAndGroup: (data) => {
        return request("POST", `/index.php/adminVirtualSimulation/createCourseAndProjectAndGroup`, data);
    },
    loginManager: (data) => {
        return request("POST", `/index.php/adminVirtualSimulation/loginManager`, data);
    },
    getTeacherList: (data) => {
        return request("POST", `/index.php/adminVirtualSimulation/getTeacherList`, data);
    },
    getReportWebList: (data) => {
        return request("POST", `/index.php/adminVirtualSimulation/getReportWebList`, data);
    },
    deleteReportWeb: (data) => {
        return request("POST", `/index.php/adminVirtualSimulation/deleteReportWeb`, data);
    },
    addReportWeb: (data) => {
        return request("POST", `/index.php/adminVirtualSimulation/addReportWeb`, data);
    },
    getReportWebDetail: (data) => {
        return request("POST", `/index.php/adminVirtualSimulation/getReportWebDetail`, data);
    },
    updateReportWeb: (data) => {
        return request("POST", `/index.php/adminVirtualSimulation/updateReportWeb`, data);
    },
    getUserInfoById: (data) => {
        return request("POST", `/index.php/adminUser/getUserInfoById`, data);
    },
    updateUserInfo: (data) => {
        return request("POST", `/index.php/adminUser/updateUserInfo`, data);
    },
};